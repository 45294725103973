import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer';
import { Container, Typography } from '@mui/material';
import CourseAccordion from '../Modules';
import Projects from '../Projects';
import "./Hero.css"
import Roadmap from './RoadMap';
import MasterTools from './Master';
import Faqs from './Faqs';
import Hiring from './Hiring';
import axios from 'axios';
import { notification } from 'antd';

import { API_URL } from '../../constants/constants';
import { useNavigate } from 'react-router-dom';

const CourseIntro = () => {
  const navigate=useNavigate()

  const copyToClipboard = () => {
    // Check if the browser supports the clipboard API
    if (navigator.clipboard) {
      // Get the current URL
      const currentURL = window.location.href;

      // Use the clipboard API to copy the URL to the clipboard
      navigator.clipboard.writeText(currentURL).then(() => {
        alert('Link copied to clipboard!');
      }).catch(err => {
        console.error('Could not copy text: ', err);
      });
    } else {
      // Fallback for browsers that don't support the clipboard API
      alert('Your browser does not support clipboard copying.');
    }
  };
  const [isEnrollDisabled, setIsEnrollDisabled] = useState(false);
  const token = localStorage.getItem("User");
  const[enrolled,setEnrolled]=useState("")


  const openNotification = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  const createOrder = async () => {
    if (!token) {
      openNotification('error', 'Authentication Error', 'Please try again. Token is missing.');
      setIsEnrollDisabled(false);
      return;
    }

    const createOrderUrl = `${API_URL}/api/v1/create-order`;
    const createOrderPayload = { courseId: '6714fafb05b50d78c0747c30' };
    const createOrderConfig = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
    };

    try {
      const response = await axios.post(createOrderUrl, createOrderPayload, createOrderConfig);
      return response.data; // { order_id, ... }
    } catch (error) {
      console.error('Error creating order:', error);
      openNotification('error', 'Order Creation Failed', 'Failed to create order. Please try again.');
      setIsEnrollDisabled(false);
    }
  };

  const verifyPayment = async (order_id, payment_id, signature) => {
    const verifyPaymentUrl = `${API_URL}/api/v1/verify-payment`;
    const verifyPaymentPayload = { order_id, payment_id, signature };
    const verifyPaymentConfig = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
    };

    try {
      const response = await axios.post(verifyPaymentUrl, verifyPaymentPayload, verifyPaymentConfig);
      if (response.status === 200) {
        openNotification('success', 'Payment Verified', 'Your payment has been successfully verified.');
        setIsEnrollDisabled(false);
      }
    } catch (error) {
      console.error('Error verifying payment:', error);
      openNotification('error', 'Payment Verification Failed', 'Failed to verify payment. Please try again.');
      setIsEnrollDisabled(false);
    }
  };

  const paymentHandler = async (e) => {
    e.preventDefault();
    setIsEnrollDisabled(true);

    try {
      const { order_id } = await createOrder();
      if (!order_id) return;

      const options = {
        key: "rzp_live_dyuixktZbIysaG",
        name: "Korpready",
        description: "Test Transaction",
        image: "https://example.com/your_logo",
        order_id: order_id,
        handler: async function (response) {
          const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response;
          await verifyPayment(razorpay_order_id, razorpay_payment_id, razorpay_signature);
          await courseEmailNotification(order_id);
          await courseEnrolled()


        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        console.error('Payment failed:', response.error);
        openNotification('error', 'Payment Failed', 'Your payment could not be processed. Please try again.');
        setIsEnrollDisabled(false);
      });
      rzp1.open();
    } catch (error) {
      console.error('Error during payment process:', error);
      openNotification('error', 'Payment Error', 'An error occurred during the payment process.');
      setIsEnrollDisabled(false);
    }
  };
  const handelenroll=(event)=>{
    const token=localStorage.getItem("User")
    if(!token){
      window.location.href = "/login";
    }
    else{
      paymentHandler(event)
    }
  
  }
  const courseEnrolled=async()=>{
    const createOrderUrl = `${API_URL}/api/v1/is-enrolled`;
    const createOrderPayload = { courseId: '6714fafb05b50d78c0747c30' };
    const createOrderConfig = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
    };
  
    try {
      const response = await axios.get(`${createOrderUrl}/${createOrderPayload.courseId}` , createOrderConfig);
      console.log(response,"res")
       setEnrolled(response.data.success);
    } catch (error) {
      console.error('Error creating order:', error);
      // openNotification('error', 'Order Creation Failed', 'Failed to create order. Please try again.');
      setIsEnrollDisabled(false);
    }
  }
  useEffect(()=>{
    if(token){
    courseEnrolled()
    }
  
  },[])
  const courseEmailNotification=async(order_id)=>{
    const createOrderUrl = `${API_URL}/api/v1/email-payment-details`;
    const createOrderPayload = {order_id: order_id };
    const createOrderConfig = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      
    };
  
    try {
      const response = await axios.post(createOrderUrl ,createOrderPayload, createOrderConfig);
      console.log(response,"res")
      // setEnrolled(response.data.success); // { order_id, ... }
    } catch (error) {
      console.error('Error creating order:', error);
      openNotification('error', 'Order Creation Failed', 'Failed to create order. Please try again.');
      setIsEnrollDisabled(false);
    }
  }
  return (
    <>
    
    
    <div className='course-intro-m'
    style={{}}
    >

    <div className="course-hero-section">
      <div className="hero-box hero-text">
      <Typography variant="body2" gutterBottom>
               
                <span style={{ color: 'white',}}>
  <a href="/" style={{ color: 'white', textDecoration: 'none' }}>🏠Home</a> {" "}
  &gt; {" "}
  <a href="/courses/details" style={{ color: 'white', textDecoration: 'none' }}>Courses</a> {" "}
  &gt; {" "}
  <a href="/coursedetails" style={{ color: 'white', textDecoration: 'none' }}>Data Science with Gen AI</a>{" "}
</span>

              </Typography>
              <h1 style={{color:"rgb(235 238 242)"}}>Data Science With Gen AI</h1>
        
        <p style={{color:"rgb(235 238 242)"}}>This beginner-friendly course is designed to introduce you to the exciting world of data science. You will learn the basics of Python programming, essential statistics, and introductory concepts in machine learning. Through hands-on exercises, you’ll explore how to analyze data, build  machine learning models, and understand foundational deep learning & NLP  techniques. By the end of this course, you'll have a solid understanding of key concepts in data science, empowering you to pursue further studies or start your career in this dynamic field.</p>
        <div className="courses-details--pricing">
          <p className="courses-details--price">₹ 4999</p>
          <p className="courses-details--discount">₹ 85000</p>
          <p className="courses-details--discount-label" >95% Discount</p>
        </div>
        <div className='d-flex g-2'
        style={{gap:"10px"}}
        >
        <button  className='course-button-share' onClick={copyToClipboard}>Share</button>
        {enrolled==true?  
         <a          className="course-button-enroll-2a"
         href="https://canvas.instructure.com/login/canvas" target='_blaank'>
            {/* <button
              // onClick={()=>{navigate("https://canvas.instructure.com/login/canvas")}}
              // disabled={isEnrollDisabled}
            > */}
              Go to Course
            {/* </button>  */}
             </a>
            :
            <button
              className="course-button-enroll-2"
              onClick={(event)=>handelenroll(event)}
              disabled={isEnrollDisabled}
            >
              Enroll
            </button>}
        {/* <button className='course-button-enroll' 
              disabled={isEnrollDisabled} onClick={(event)=>{handelenroll(event)}}>Enroll</button> */}

        </div>

      </div>
      <div className="hero-box hero-image">
        {/* <img src='./images/image-hr-01.png' alt="Learning with iNeuron" /> */}
      </div>
    </div>

    <div className="dt-container">
            <div className="dt-content">
              <div className="dt-item">
                <h3 className="dt-heading"> Live Instructor Led</h3>
                <p className="dt-subheading">Course Type</p>
              </div>
              <div className="dt-item">
                <h3 className="dt-heading">26th Dec'24</h3>
                <p className="dt-subheading">Start Date</p>
              </div>
              <div className="dt-item">
                <h3 className="dt-heading">Telugu & English</h3>
                <p className="dt-subheading">Language</p>
              </div>
              <div className="dt-item">
                <h3 className="dt-heading">4-5 Month</h3>
                <p className="dt-subheading">Duration</p>
              </div>
              <div className="dt-item">
                <h3 className="dt-heading">7:30 PM,Mon-Sat</h3>
                <p className="dt-subheading">Class Timings</p>
              </div>
            </div>
          </div>

    </div>
    <Container>
    <Roadmap/>
    <MasterTools/>

    <CourseAccordion />
    <Hiring/>
    <Faqs/>
    </Container>

    {/* <Projects /> */}
    
    </>


  )
}

export default CourseIntro;