import React, { useEffect, useState } from 'react';
import './Courses.css';
import { CgProfile } from "react-icons/cg";
import { RiDiscountPercentFill } from "react-icons/ri";
import Navbar from './Navbar/Navbar.js';
import Footer from './Footer.js';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Courses from "../Images/courses.png";
import axios from 'axios';
import { notification } from 'antd';
import { API_URL } from '../constants/constants.js';

// import 'antd/dist/antd.css'; // Import Ant Design styles
const CoursesDetails = () => {
  const [isEnrollDisabled, setIsEnrollDisabled] = useState(false);
  const token = localStorage.getItem("User");
  const[enrolled,setEnrolled]=useState("")

  const openNotification = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  const createOrder = async () => {
    debugger;
    if (!token) {
      openNotification('error', 'Authentication Error', 'Please try again. Token is missing.');
      setIsEnrollDisabled(false);
      return;
    }

    const createOrderUrl = `${API_URL}/api/v1/create-order`;
    const createOrderPayload = { courseId: '6714fafb05b50d78c0747c30' };
    const createOrderConfig = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
    };

    try {
      const response = await axios.post(createOrderUrl, createOrderPayload, createOrderConfig);
      return response.data; // { order_id, ... }
    } catch (error) {
      console.error('Error creating order:', error);
      // openNotification('error', 'Order Creation Failed', 'Failed to create order. Please try again.');
      // setIsEnrollDisabled(false);
    }
  };
  

  const verifyPayment = async (order_id, payment_id, signature) => {
    const verifyPaymentUrl = `${API_URL}/api/v1/verify-payment`;
    const verifyPaymentPayload = { order_id, payment_id, signature };
    const verifyPaymentConfig = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
    };

    try {
      const response = await axios.post(verifyPaymentUrl, verifyPaymentPayload, verifyPaymentConfig);
      if (response.status === 200) {
        openNotification('success', 'Payment Verified', 'Your payment has been successfully verified.');
        setIsEnrollDisabled(false);
      }
    } catch (error) {
      console.error('Error verifying payment:', error);
      openNotification('error', 'Payment Verification Failed', 'Failed to verify payment. Please try again.');
      setIsEnrollDisabled(false);
    }
  };

  const paymentHandler = async (e) => {
    e.preventDefault();
    setIsEnrollDisabled(true);

    try {
      const { order_id } = await createOrder();
      if (!order_id) return;

      const options = {
        key: "rzp_live_dyuixktZbIysaG",
        name: "Korpready",
        description: "Test Transaction",
        image: "https://example.com/your_logo",
        order_id: order_id,
        handler: async function (response) {
          const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response;
          await verifyPayment(razorpay_order_id, razorpay_payment_id, razorpay_signature);
          await courseEmailNotification(order_id)
          await courseEnrolled()

        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        console.error('Payment failed:', response.error);
        openNotification('error', 'Payment Failed', 'Your payment could not be processed. Please try again.');
        setIsEnrollDisabled(false);
      });
      rzp1.open();
    } catch (error) {
      console.error('Error during payment process:', error);
      openNotification('error', 'Payment Error', 'An error occurred during the payment process.');
      setIsEnrollDisabled(false);
    }
  };
  const handelenroll=(e)=>{
    const token=localStorage.getItem("User")
    if(!token){
      window.location.href = "/login";
    }
    else{
      paymentHandler(e)
    }
  
  }
const courseEnrolled=async()=>{
  const createOrderUrl = `${API_URL}/api/v1/is-enrolled`;
  const createOrderPayload = { courseId: '6714fafb05b50d78c0747c30' };
  const createOrderConfig = {
    headers: {
      'Content-Type': 'application/json',
      'x-auth-token': token,
    },
  };

  try {
    const response = await axios.get(`${createOrderUrl}/${createOrderPayload.courseId}` , createOrderConfig);
    console.log(response,"res")
    setEnrolled(response.data.success); // { order_id, ... }
  } catch (error) {
    console.error('Error creating order:', error);
    // openNotification('error', 'Order Creation Failed', 'Failed to create order. Please try again.');
    // setIsEnrollDisabled(false);
  }
}
useEffect(()=>{
  if(token){
  courseEnrolled()
  }

},[])
const courseEmailNotification=async(order_id)=>{
  const createOrderUrl = `${API_URL}/api/v1/email-payment-details`;
  const createOrderPayload = {order_id: order_id };
  const createOrderConfig = {
    headers: {
      'Content-Type': 'application/json',
      'x-auth-token': token,
    },
    
  };

  try {
    const response = await axios.post(createOrderUrl ,createOrderPayload, createOrderConfig);
    console.log(response,"res")
    // setEnrolled(response.data.success); // { order_id, ... }
  } catch (error) {
    console.error('Error creating order:', error);
    openNotification('error', 'Order Creation Failed', 'Failed to create order. Please try again.');
    setIsEnrollDisabled(false);
  }
}

  return (
    <>
      <Navbar />
      <header className="header mt-3 d-inline" id='courses'>
        <div style={{ width: "80%", margin: "auto", marginTop: "20px" }}>
          <Typography variant="body2" gutterBottom>
            <Link to="/" style={{ color: "black", textDecoration: "none" }}>🏠 Home</Link> {" > "}
            <Link to="/courses/details" style={{ color: "black", textDecoration: "none" }}>Courses</Link>
          </Typography>
          <h2 className="title">Our Courses</h2>
        </div>
      </header>
      <Box sx={{ width: "80%", margin: "auto" }}>
        <div className="course-container">
          <div className="course-box">
            <img
              onClick={() => { window.location.href = "/coursedetails"; }}
              src={Courses}
              alt="Data Science With Gen AI"
              className="course-image"
            />
            <h1 className="course-heading">Data Science With Gen AI</h1>
            <div className="course-paragraphs">
              <p className="course-paragraph">
                <CgProfile className="paragraph-icon" /> Detailed explanation of Data Science Master Pro 2024.
              </p>
              <p className="course-paragraph">
                <CgProfile className="paragraph-icon" /> Key features of Data Science Master Pro 2024.
              </p>
              <p className="course-paragraph">
                <CgProfile className="paragraph-icon" /> College Students & Working Professionals.
              </p>
            </div>
            <hr style={{ width: '70%', margin: 'auto', marginTop: '15px' }} />
            <div className="course-price-info">
              <h6 className="price">₹ 4999.00</h6>
              <p className="price-strikethrough" style={{ padding: "0px 1px", marginTop: "-1px" }}>85000</p>
              <h6 className="discount">
                <RiDiscountPercentFill className="discount-icon" />
                <span style={{ color: 'green' }}>95% Discount</span>
              </h6>
            </div>
            <button className="course-button-2" onClick={() => { window.location.href = "/coursedetails"; }}>
              Explore
            </button>
            {enrolled==true?  
           <a 
            href="https://canvas.instructure.com/login/canvas" target='_blaank'>
              <button
             className="course-button-enroll-2-a"
            >   Go to Course
</button>
            </a>:
            <button
              className="course-button-enroll-2"
              onClick={(e)=>handelenroll(e)}
              disabled={isEnrollDisabled}
            >
              Enroll
            </button>}
          </div>
        </div>
      </Box>
      <Footer />
    </>
  );
};

export default CoursesDetails;
