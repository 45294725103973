import React from 'react';
import './ComingSoon.css';
import Footer from './Footer';
import Navbar from './Navbar/Navbar';

const ComingSoon = () => {
  return (
    <>
    <Navbar/>
    <div className="coming-soon-container">
      <h1 className="coming-soon-title">Coming Soon</h1>
      <p className="coming-soon-subtitle">Something amazing is on its way!</p>
    </div>
    <Footer/>

    </>
  );
};

export default ComingSoon;
