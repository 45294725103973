import React, { useState } from "react";
import { Button, Container, Card, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Logo from "../Images/Logo.png";
import { API_URL } from "../constants/constants";
import { notification } from 'antd';


const EmailVerification = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [apiResponse, setApiResponse] = useState(null); 
  const [loading, setLoading] = useState(true); 
  const[error,setError]=useState("")
  const navigate = useNavigate();
  const token=useParams()
  console.log(token,"token")
  const openNotification = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  const verifyEmail = async () => {
    setError("")
    try {
        const response = await axios.get(`${API_URL}/api/v1/email-verify/${token?.token}`);
        console.log(response,"token")
        setApiResponse(response.data);
        setError("")
        setLoading(false);
        openNotification("success","Your Account has successfully Verified","")

        setTimeout(() => {
            navigate('/login');
        }, 2000);
    } catch (error) {
        setError(error.response.data.message)
        console.error('Email verification failed:', error);
        setLoading(false); // Stop loading even on failure
    }
};

  return (
    <Container className="d-flex justify-content-center align-items-center vh-100">
      <Card style={{ width: "25rem", padding: "1.5rem" }} className="shadow">
        <div className="text-center">
          <img
            src={Logo}
            alt="Email Icon"
            className="mb-3 navbar-logo w-100"
          />
          <h5>Verify your email address</h5>
          <p>
            In order to start using your  account, you need to confirm
            your email address.
          </p>
          <Button
            variant="primary"
            disabled={isLoading || isVerified}
            onClick={verifyEmail}
            className="w-100"
          >
            {isLoading ? "Verifying..." : "Verify Email Address"}
          </Button>
          {error && (
            <Alert variant="danger" className="mt-3">
              {error}
            </Alert>
          )}
          <p className="mt-3 text-muted" style={{ fontSize: "0.9rem" }}>
            If you did not sign up for this account, you can ignore this email
            and the account will be deleted.
          </p>
        </div>
      </Card>
    </Container>
  );
};

export default EmailVerification;
